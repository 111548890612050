import styled from "styled-components";
import { Colors, Rem } from "../../commons/Theme";

const height = 370;
const scrollControllerHeight = 24;
const width = 180;
const lineThick = 5;
const circleRadius = 8;
const circleBorderThick = 8;
const paddingToCircle = 8;

export const StyledTimeline = styled.div`
  background-color: ${Colors.black};
  color: ${Colors.white};
  height: ${height+scrollControllerHeight}px;
  
`;

export const StyledTimelineContainer = styled.div`
  position: relative;
  height: ${height}px;
`;

export const StyledTimelineItems = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: row;
  padding: 0 30px;
`;

export const StyledTimelineItemLine = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:before {
    position: relative;
    content: '';
    display: block;
    width: 100%;
    height: ${lineThick}px;
    top: calc(50% - ${lineThick/2}px);
    background-color: ${Colors.white};
  }
  
`;

export const StyledTimelineItemCircle = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &:before {
    position: relative;
    content: '';
    display: block;
    width: ${circleRadius*2}px;
    height: ${circleRadius*2}px;
    border-radius: 50%;
    border: ${circleBorderThick}px solid ${Colors.yellow};
    top: calc(50% - ${circleRadius+circleBorderThick}px);
    left: calc(50% - ${circleRadius+circleBorderThick}px);
    background-color: ${Colors.white};
  }
`;

export const StyledTimelineItem = styled.div`
  position: absolute;
  width: 100%;

  display: flex;
  flex-direction: column;

  &:nth-child(1) {
    bottom: 50%;
    padding-top: 0;
    padding-bottom: ${circleRadius+circleBorderThick+paddingToCircle}px;
  }

  &:nth-child(2) {
    top: 50%;
    padding-top: ${circleRadius+circleBorderThick+paddingToCircle}px;
    padding-bottom: 0;
  }
`;

export const StyledTimelineItemContainer = styled.div`
  position: relative;
  width: ${width}px;
  height: 100%;

  &:first-of-type {
    ${StyledTimelineItemLine} {
      &:before {
        left: 50%;
        width: 50%;
      }
    }
  }

  &:last-of-type {
    ${StyledTimelineItemLine} {
      &:before {
        width: 50%;
      }
    }
  }

  &:nth-child(even) {
    ${StyledTimelineItem} {
      flex-direction: column-reverse;

      &:nth-child(2) {
        padding-bottom: ${circleRadius+circleBorderThick+paddingToCircle}px;
        padding-top: 0;
        bottom: 50%;
        top: auto;
      }
    
      &:nth-child(1) {
        padding-top: ${circleRadius+circleBorderThick+paddingToCircle}px;
        padding-bottom: 0;
        top: 50%;
        bottom: auto;
      }
    }
  }
`;

export const StyledTimelineDate = styled.p`
  text-align: center;
  font-weight: 100;
  font-size: ${Rem(15)};
`;

export const StyledTimelineTitle = styled.p`
  text-align: center;
  font-size: ${Rem(17)};
  line-height: ${Rem(24)};
`;

export const StyledTimelineImage = styled.div`
  margin: 10px 0;
  width: 100%;
  height: 44px;
  background-image: url('${(props) => props.src}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;
